import React, { useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ethers } from "ethers";
import { MiningPoolAbi, nftAbi, tokenAbi } from "../../../../utils/config";
import { eventBus } from "../../../../utils/tool";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import FileUploder from '../FileUploader/index.jsx';
import { useTranslation } from 'react-i18next';
import { uploadText } from "../../../../utils/ipfs"


export default function StakeModal(props) {

    const { t } = useTranslation();
    const [errorMsg, setErrorMsg] = useState(''); // 错误信息
    const [currentProvider, setCurrentProvider] = useState(null); // 当前的provider

    const [ipfsInfo, setIpfsInfo] = useState({}); // ipfs信息
    const [ipfsLoading, setIpfsLoading] = useState(false) // ipfs中loading
    const urlPlaceholder = ""

    useImperativeHandle(props.onRef, () => {
        // 需要将暴露的接口返回出去
        return {
            setIpfsInfo: setIpfsInfoLogic,
        };
    });

    const setIpfsInfoLogic = (info) => {
        // 设置ipfs信息
        return new Promise((resolve, reject) => {
            console.log('设置ipfs信息')
            const r = Object.assign({ project_name: '', top_bg: '' }, info)
            setIpfsInfo(r)
            resolve()
        })
    }

    const initProvider = () => {
        if (currentProvider) {
            return currentProvider
        }
        if (window.ethereum && !currentProvider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            setCurrentProvider(provider)
            return provider
        } else {
            console.log('No provider')
        }
    }


    const handleErr = (e) => {
        console.log(e)
        let t = ''
        if (e.reason) {
            t = e.reason
        } else {
            t = JSON.stringify(e)
        }
        setErrorMsg(t)
    }




    return <>
        <Modal centered show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Header closeButton>
                <Modal.Title>{t('设置项目信息')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col>
                            <Alert variant="danger" show={errorMsg ? true : false} >
                                {errorMsg}
                            </Alert>
                        </Col>
                    </Row>

                    <Form style={{ 'overflowY': 'scroll', 'maxHeight': '400px' }}>
                        {/* 质押代币logo 奖励代币logo 官网 推特 telegram youtube reddit facebook github discord instagram 其他字段 质押规则 */}
                        <Row className="stake-project-row">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="stake-project-label">{t('项目名称')}</Form.Label>
                                    <Form.Control onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            project_name: e.target.value
                                        })
                                    }} value={ipfsInfo.project_name} className="ipfs-info-input" type="text" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="stake-project-row">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="stake-project-label">{t('顶部背景图')}</Form.Label>
                                    {/* <Form.Control className="ipfs-info-input" type="text" /> */}
                                    <FileUploder onBeforeUpload={(f) => {
                                        console.log(f)
                                        if (f.type.indexOf('image') === -1) {
                                            setErrorMsg(t('文件格式不正确'))
                                            return false
                                        }
                                        if (f.size > 1024 * 500) {
                                            setErrorMsg(t('文件大小不能超过500kb').replace('{size}', '500'))
                                            return false
                                        }
                                        return true
                                    }} url={ipfsInfo.top_bg || ''} width="100%" height="100px" onFileUpload={(url) => {
                                        // 设置背景图
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            top_bg: url
                                        })

                                    }} />

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="stake-project-row">
                            <Col sm={6} >
                                <Form.Group>
                                    <Form.Label className="stake-project-label">{t('项目LOGO')}</Form.Label>
                                    <FileUploder onBeforeUpload={(f) => {
                                        console.log(f)
                                        if (f.type.indexOf('image') === -1) {
                                            setErrorMsg(t('文件格式不正确'))
                                            return false
                                        }
                                        if (f.size > 1024 * 200) {
                                            setErrorMsg(t('文件大小不能超过500kb').replace('{size}', '200'))
                                            return false
                                        }
                                        return true
                                    }} url={ipfsInfo.logo || ''} width="100px" height="100px" onFileUpload={(url) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            logo: url
                                        })
                                    }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="stake-project-row">
                            <Col sm={6} >
                                <Form.Group>
                                    <Form.Label className="stake-project-label">{t('官网')}</Form.Label>
                                    <Form.Control value={ipfsInfo.website} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            website: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label className="stake-project-label">{t('推特')}</Form.Label>
                                    <Form.Control value={ipfsInfo.twitter} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            twitter: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                        </Row>

                        <Row className="stake-project-row">
                            <Col sm={6} >
                                <Form.Group>
                                    <Form.Label className="stake-project-label">Telegram</Form.Label>
                                    <Form.Control value={ipfsInfo.telegram} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            telegram: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label className="stake-project-label">Youtube</Form.Label>
                                    <Form.Control value={ipfsInfo.youtube} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            youtube: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                        </Row>

                        <Row className="stake-project-row">
                            <Col sm={6} >
                                <Form.Group>
                                    <Form.Label className="stake-project-label">Reddit</Form.Label>
                                    <Form.Control value={ipfsInfo.reddit} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            reddit: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label className="stake-project-label">Facebook</Form.Label>
                                    <Form.Control value={ipfsInfo.facebook} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            facebook: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                        </Row>
                        <Row className="stake-project-row">
                            <Col sm={6} >
                                <Form.Group>
                                    <Form.Label className="stake-project-label">Github</Form.Label>
                                    <Form.Control value={ipfsInfo.github} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            github: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label className="stake-project-label">Discord</Form.Label>
                                    <Form.Control value={ipfsInfo.discord} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            discord: e.target.value
                                        })
                                    }} className="ipfs-info-input" type="text" placeholder={urlPlaceholder} />
                                </Form.Group></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mt-2">
                                    <Form.Label className="stake-project-label">{t('项目简介')}</Form.Label>
                                    <Form.Control value={ipfsInfo.description} onChange={(e) => {
                                        setIpfsInfo({
                                            ...ipfsInfo,
                                            description: e.target.value
                                        })
                                    }} className="ipfs-info-input" as="textarea" rows={3} />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* 展示其他字段 */}
                        {ipfsInfo.extra && ipfsInfo.extra.map((item, index) => {
                            return <Row key={index}>
                                <Col>
                                    <Form.Group className="mt-2">
                                        <Form.Label className="stake-project-label mt-2" style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center' }}>
                                            <Form.Control onChange={(e) => {
                                                const info = JSON.parse(JSON.stringify(ipfsInfo))
                                                info.extra[index].name = e.target.value
                                                setIpfsInfo(info)

                                            }} style={{ 'width': '150px' }} className="ipfs-info-input" value={item.name} />
                                            <span onClick={() => {
                                                const info = JSON.parse(JSON.stringify(ipfsInfo))
                                                console.log(info)
                                                info.extra.splice(index, 1)
                                                console.log(info)
                                                setIpfsInfo(info)
                                            }}>
                                                <i style={{ 'fontSize': '12px', 'marginLeft': '7px' }} className="fas fa-trash-alt"></i>
                                            </span>
                                        </Form.Label>
                                        <Form.Control value={item.content} onChange={(e) => {
                                            const info = JSON.parse(JSON.stringify(ipfsInfo))
                                            info.extra[index].content = e.target.value
                                            setIpfsInfo(info)
                                        }} className="ipfs-info-input mt-2" as="textarea" rows={3} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        })}

                        <Row>
                            <Col>
                                <div onClick={() => {
                                    const info = JSON.parse(JSON.stringify(ipfsInfo))
                                    info.extra.push({
                                        name: '',
                                        content: ''
                                    })
                                    setIpfsInfo(info)
                                }} style={{ 'fontSize': '12px' }} className="btn btn-bordered-white btn-smaller mt-2">
                                    {t('添加字段')}
                                </div>
                            </Col>
                        </Row>

                    </Form>
                    <Form>
                        <Row className="stake-project-row">
                            <Col>
                                <Form.Group className="stake-project-btn mt-2">
                                    <div onClick={async () => {
                                        // 上传ipfs
                                        // uploadIpfs()
                                        setErrorMsg('')
                                        // 验证参数是否正确, 不正确则展示错误信息
                                        setIpfsLoading(true)
                                        // 更新到ipfs

                                        try {
                                            const p = await uploadText(JSON.stringify(ipfsInfo))
                                            // 调用合约
                                            const pp = initProvider()
                                            const contract = new ethers.Contract(props.contract, nftAbi, pp.getSigner())
                                            const tx = await contract.setProjectIpfs(p.path)
                                            await tx.wait()
                                            props.ok && props.ok(p)
                                        } catch (e) {
                                            console.log(e)
                                            handleErr(e)
                                        }
                                        setIpfsLoading(false)
                                    }} className="btn btn-bordered-white btn-smaller">
                                        {ipfsLoading && <span>
                                            <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                                        </span>}
                                        {t('提交')}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </Modal.Body>
        </Modal>
    </>
}