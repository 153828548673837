import React, { Component } from 'react';
import { connect } from "react-redux"
class Breadcrumb extends Component {
    render() {
        return (
            <section style={{ 'backgroundImage': `url("${this.props?.miningInfo?.top_bg || '/img/inner_bg.jpeg'}")` }} className="breadcrumb-area overlay-dark d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        {/* 仅项目方铸造, 返回 */}
                        {this.props.nftInfo?.isOnlyAdmin === true && <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 onClick={() => {
                                    console.log(this.props)
                                }} className="m-0">{this.props.nftInfo?.name}</h2>
                                <div className="breadcrumb d-flex  justify-content-center">
                                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span> 简称:  </span>  <span>   {this.props.nftInfo?.symbol} </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span> 总量:  </span>  <span>   {this.props.nftInfo?.totalSupply?.toString()} </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span> 铸造方式:  </span>  <span>   项目方铸造 </span>
                                        </div>
                                    </div>
                                </div>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item">{this.props.desc}</li>
                                    {/* <li className="breadcrumb-item"><a href="#">{this.props.subpage}</a></li>
                                    <li className="breadcrumb-item active">{this.props.page}</li> */}
                                </ol>
                            </div>
                        </div>}
                        {/* 散户铸造, 显示项目信息 */}
                        {this.props.nftInfo?.isOnlyAdmin === false && <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 onClick={() => {
                                    console.log(this.props)
                                }} className="m-0">{this.props.miningInfo?.project_name}</h2>
                                <div className="breadcrumb d-flex justify-content-center">
                                    {this.props.miningInfo?.description}

                                </div>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item">{this.props.desc}</li>
                                    {/* <li className="breadcrumb-item"><a href="#">{this.props.subpage}</a></li>
                                    <li className="breadcrumb-item active">{this.props.page}</li> */}
                                </ol>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
        );
    }
}


function mapStateToProps(state) {
    const miningInfo = state.miningInfo;
    const nftInfo = state.globalNftInfo;
    return {
        miningInfo,
        nftInfo
        // text
    };
}

// export default ProjectSingle;
export default connect(mapStateToProps)(Breadcrumb)

// export default Breadcrumb;