import { createStore } from 'redux'

const initState = {
    account: ''
}

const addReducer = (state = initState, action) => {
    switch (action.type) {
        case 'setAccount':
            return {
                ...state,
                account: action.value
            }
        case 'setChainId':
            return {
                ...state,
                chainId: action.value
            }
        case 'reduce':
            return {
                ...state,
            }
        case 'setNftInfo':
            return {
                ...state,
                globalNftInfo: action.value
            }
        case 'setMiningInfo':
            return {
                ...state,
                miningInfo: action.value
            }
        default:
            return {
                ...state
            }
    }
}
const store = createStore(addReducer)
export default store
