import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
// import Staking from '../components/Staking/StakingOne';
import Footer from '../components/Footer/Footer';
import NFTConsoleOne from '../components/NFTConsole/index'


class NFTConsole extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Breadcrumb title="Staking Style 1" subpage="Staking" page="Staking Style 1" />
                <NFTConsoleOne />
                <Footer />
            </div>
        );
    }
}

export default NFTConsole;