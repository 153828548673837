import React, { useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getRemoteUrlContent } from "../../utils/ipfs";
export default function NftCard(props) {
    const [contentType, setContentType] = useState('image/png');
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState({})
    const [id, setId] = useState(0)
    const { t } = useTranslation();
    const handleLoaded = () => {
        setLoading(false)
    }

    const fetchData = async () => {
        const u = await getRemoteUrlContent(props.info.url)
        console.log('uuuuu,', JSON.parse(u))
        setId(props.info.id)
        setInfo(JSON.parse(u))
    }

    useEffect(() => {
        fetchData()
    }, [props.info])

    return <div className="nft-card">
        <div style={{
            width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
            {loading && <div>
                <i style={{ 'fontSize': '3rem', fontWeight: 'bold' }} className="fa fa-spinner loading-css"></i>
            </div>}
            {contentType.startsWith('image') && <img onError={() => {
                setContentType('audio/mpeg')
            }} onLoad={handleLoaded} style={{ width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} alt="" src={info.image} />}
            {contentType.startsWith('audio') && <audio onError={() => {
                setContentType('video/mp4')
            }} onLoad={handleLoaded} src={info.image} controls>
                Your browser does not support the audio element.
            </audio>}
            {contentType.startsWith('video') && <video onError={() => {
                setContentType('')
            }} onLoad={handleLoaded} src={info.image} controls>
                Your browser does not support the video element.
            </video>}
        </div>
        <div style={{ width: '100%', padding: '15px' }}>
            {id > 0 && <div style={{ width: '100%' }} className="mt-2">
                <div style={{ fontWeight: 'bold', color: '#ffffff', fontSize: '1.2rem' }}>#{id}</div>
            </div>}
            {info.name && <div className="mt-2 name-box-control" style={{ color: '#F9F9F9', width: '100%' }}>{info.name}</div>}
            {info.desc && <div className="mt-2 desc-box-control" style={{ color: '#E1E1E1', width: '100%' }}>{info.description}</div>}
        </div>
    </div >

}