import React, { useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
export default function SuccessModal(props) {
    const [contentType, setContentType] = useState('image/png');
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const handleLoaded = () => {
        setLoading(false)
    }

    return <Modal centered show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }}>
        <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{
                    width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
                }}>
                    {loading && <div>
                        <i style={{ 'fontSize': '3rem', fontWeight: 'bold' }} className="fa fa-spinner loading-css"></i>
                    </div>}
                    {contentType.startsWith('image') && <img onError={() => {
                        setContentType('audio/mpeg')
                    }} onLoad={handleLoaded} style={{ maxHeight: '300px' }} alt="" src={props.url} />}
                    {contentType.startsWith('audio') && <audio onError={() => {
                        setContentType('video/mp4')
                    }} onLoad={handleLoaded} src={props.url} controls>
                        Your browser does not support the audio element.
                    </audio>}
                    {contentType.startsWith('video') && <video onError={() => {
                        setContentType('')
                    }} onLoad={handleLoaded} src={props} controls>
                        Your browser does not support the video element.
                    </video>}
                </div>
                <div>
                    {props.id > 0 && <div className="mt-2">
                        <div style={{ fontWeight: 'bold', color: '#ffffff', fontSize: '2rem' }}>#{props.id}</div>
                    </div>}
                    {props.name && <div className="mt-2 name-box-control" style={{ color: '#F9F9F9' }}>{props.name}</div>}
                    {props.desc && <div className="mt-2 desc-box-control" style={{ color: '#E1E1E1' }}>{props.description}</div>}
                </div>
                <div className="mt-2" style={{
                    width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
                }}>
                    <div className="btn btn-bordered-white  btn-smaller" onClick={() => {
                        props.handleClose && props.handleClose()
                    }}> 🎉 {t('铸造成功')}</div>
                </div>
            </div>


        </Modal.Body >
    </Modal >

}